import Table from "react-bootstrap/Table";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllMessages, reset } from "../features/messages/messageSlice";
import Spinner from "../components/common/Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Messages = () => {
  const { messages, isError, isLoading, message } = useSelector(
    (state) => state.message
  );
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isError) {
      // console.log(message);
      toast.error(message);
    }

    if (!user) {
      navigate("/login");
    }

    if (user) {
      dispatch(getAllMessages());
    }

    return () => {
      dispatch(reset());
    };
  }, [user, dispatch, message, isError, navigate]);

  if (isError) {
    // console.log(message);
    toast.error(message);
  }

  useEffect(() => console.log(messages), [messages])

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="container">
      <div className="d-flex flex-column gap-4 px-2 py-3 px-md-3">
        <div className="pt-3">
          <h2>Messages</h2>
        </div>
        <div className="px-0 py-3 px-md-3">
          <Table
            responsive
            className="table table-hover table-bordered overflow-x-scroll"
          >
            <thead>
              <tr>
                <th scope="col">Email</th>
                <th scope="col">Subject</th>
                <th scope="col">Message</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((message) => {
                return (
                  <tr
                    key={message._id}
                  >
                    <td className="nowrap">{message.email}</td>
                    <td>{message.subject}</td>
                    <td>{message.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
export default Messages;
