import { useEffect, useState } from "react";
// import { FcGoogle } from "react-icons/fc";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useNavigate } from "react-router-dom";
// import axios from "../api/axios";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [userMessageError, setUserMessageError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();


  const loginHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      if (!email) {
        setEmailError("Email is required");
      }
      if (!subject) {
        setSubjectError("Subject is required");
      }
      if (!userMessage) {
        setUserMessageError("Message is required");
      }
    } else {
      const userData = { 
        email: email,
        subject: subject,
        message: userMessage
      };
      console.log(userData)
      try {
        setIsSending(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/contact`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json' 
          },
          body: JSON.stringify(userData)
        });
        if (response.status !== 200)
          throw response;
        const data = await response.json();
        toast.success("Message sent successfully!");
        setIsSending(false);
        setTimeout(() => {
          navigate("/");
        }, 500)
      } catch (e) {
        console.log(e);
        setIsSending(false);
        toast.error("Failed to send message!");
      }
    }
  };

  return (
    <section>
      <Container
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ minHeight: "calc(100vh - 56px)" }}
      >
        <Card className="p-3 mw-100">
          <Form
            noValidate
            className="mw-100"
            onSubmit={loginHandler}
          >
            <div className="d-flex align-item-center justify-content-center py-3">
              <div>
                <h1 className="text-primary text-center">Contact Us</h1>
              </div>
            </div>
            <Col sm={12}>
              <FloatingLabel
                controlId="floatingInputEmail"
                label="Email Address"
                className="mb-1"
              >
                <Form.Control
                  required
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                />
                <Form.Control.Feedback className={"ms-3"} type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col sm={12}>
              <FloatingLabel
                controlId="floatingInputSubject"
                label="Subject"
                className="mb-1"
              >
                <Form.Control
                  required
                  name="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  type="text"
                  placeholder="Subject"
                />
                <Form.Control.Feedback className={"ms-3"} type="invalid">
                  {subjectError}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col sm={12}>
              <FloatingLabel
                controlId="floatingInputMessage"
                label="Message"
                className="mb-1"
              >
                <Form.Control
                  required
                  name="message"
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                  as="textarea"
                  placeholder="message"
                  rows={4}
                  style={{
                    height: '160px'
                  }}
                />
                <Form.Control.Feedback className={"ms-3"} type="invalid">
                  {userMessageError}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>

            <Form.Group as={Row} className="mb-4 text-center">
              <Col>
                <Button 
                  onClick={() => navigate("/")} 
                  disabled={isSending}
                  style={{
                    marginRight: '20px',
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={isSending}>{`${
                  isSending ? "Sending" : "Submit"
                }`}</Button>
              </Col>
            </Form.Group>
          </Form>
          <p className="text-center">or</p>
          <p className="text-center">Contact us at businesses@klhbusiness.com</p>
        </Card>
      </Container>
    </section>
  );
};
export default ContactUs;
