import axios from "../../api/axios";

const API_URL = "api/contact/";

// get user goals
const getAllMessages = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}`, config);
  return response.data;
};

const messagesService = {
  getAllMessages,
};

export default messagesService;
